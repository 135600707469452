export const convertToTimeStamp = (timestamp) => {
  const date = new Date(timestamp);
  const formattedDate = date.toUTCString().slice(5, 16);
  return formattedDate;
};

export const onValidEmailAddress = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const getEndTimestamp = (endDate: Date): number => {
  let d = new Date(
    Date.UTC(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      23,
      59,
      59,
      0
    )
  );
  return adjustUnixTimestamp(d);
  /* d.setHours(d.getHours() - 3);
  console.log('Eafter -3', d.getTime());
  return d.getTime(); */
};

export const getStartTimestamp = (startDate: Date): number => {
  let d = new Date(
    Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      0,
      0,
      0,
      0
    )
  );
  return adjustUnixTimestamp(d);
  /* d.setHours(d.getHours() - 3);
  console.log('Safter -3', d.getTime());
  return d.getTime(); */
};

const adjustUnixTimestamp = (localDate: Date) => {
  const timestampMs = localDate.getTime();
  const offsetMinutes = localDate.getTimezoneOffset();
  const adjustedTimestamp = timestampMs + offsetMinutes * 60 * 1000;
  return adjustedTimestamp;
};
